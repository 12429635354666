var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"5","xl":"4"}},[(_vm.strategy._id)?_c('StrategyCard',{attrs:{"height":"100%","strategy":_vm.strategy},on:{"function-run":function () { return _vm.getHistoryData(); }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","lg":"7","xl":"8"}},[(_vm.strategy.name)?_c('ListExecutions',{attrs:{"strategy":_vm.strategy}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Histórico de execução ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.historyHeaders,"items":_vm.history,"items-per-page":10,"item-class":_vm.colorRows,"must-sort":"","sort-by":"startedAt","sort-desc":"","item-key":"startedAt","show-expand":"","single-expand":"","expanded.sync":"expanded"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.running)?_c('v-progress-circular',{attrs:{"size":15,"indeterminate":"","color":"primary"}}):(item.error)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"}},[_vm._v(" mdi-alert-circle ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"}},[_vm._v(" mdi-checkbox-marked-circle ")])]}},{key:"item.startedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.startedAt).format("DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.finishedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.finishedAt).format("DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.executationTime",fn:function(ref){
var item = ref.item;
return [(item.executationTime)?_c('span',[_vm._v(" "+_vm._s(item.executationTime / 1000)+"s ")]):_c('span',[_vm._v("--")])]}},{key:"item.triggeredBy",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[(item.triggeredBy.cron)?[_vm._v(" Cron ("),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.triggeredBy.cron))]),_vm._v(") ")]:_vm._e(),(item.triggeredBy.user)?[_vm._v(" Usuário ("),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.triggeredBy.user))]),_vm._v(") ")]:_vm._e(),(item.triggeredBy.ticker)?[_vm._v(" Ticker "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.triggeredBy.ticker))])]:_vm._e()],2)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.historyHeaders.length}},[(!item.error)?_c('v-data-table',{staticClass:"my-3",attrs:{"hide-default-footer":"","disable-pagination":"","headers":_vm.headersResponse,"items":item.response}}):_c('span',[_vm._v(" "+_vm._s(item.error)+" ")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }