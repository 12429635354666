<template>
  <v-container fluid>
    <v-row class="d-flex">
      <v-col>
      <h2 class="mb-3">Overview</h2>
      </v-col>
      <v-col>
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDateC"
              label="Data inicial"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker v-model="startDate" @input="startDateMenu = false; getData()"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDateC"
              label="Data inicial"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker v-model="endDate" @input="endDateMenu = false; getData()"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table :loading="loading" :items="items" :headers="headers">
      <template v-slot:item.date="{ item }">
        {{moment(item.date).format("DD/MM/YYYY")}}
      </template>
      <template v-slot:item.lastUpdate="{ item }">
        {{moment(item.lastUpdate).format("DD/MM/YYYY HH:mm:ss")}}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';

export default {
  mixins: [
    jobScheduler([
      {
        job: (vm) => vm.getData(),
        interval: 15000,
      },
    ]),
  ],

  data: () => ({
    interval: false,
    items: [],
    startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
    startDateMenu: false,
    endDate: moment().format('YYYY-MM-DD'),
    endDateMenu: false,
    loading: false,
    headers: [
      { text: 'Fundo', value: 'fund' },
      { text: 'Estratégia', value: 'strategy' },
      { text: 'Ticker', value: 'ticker' },
      { text: 'Data', value: 'date' },
      { text: 'Atualizado', value: 'lastUpdate' },
      { text: 'Tipo', value: 'type' },
      { text: 'Evento', value: 'event' },
      { text: 'Preço', value: 'refPrice' },
      { text: 'Qtd', value: 'amount' },
      { text: 'V', value: 'version' },
    ],
  }),

  computed: {
    startDateC() {
      return this.parseDate(this.startDate);
    },

    endDateC() {
      return this.parseDate(this.endDate);
    },
  },

  methods: {
    moment,
    parseDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    async getData() {
      this.loading = true;
      const { data } = await api.quant.getExecutionsOverview({
        params: {
          date: `${moment(this.startDate).format('YYYY-MM-DD')}:${moment(this.endDate).format('YYYY-MM-DD')}`,
        },
      });
      this.items = data;
      this.loading = false;
    },
  },
};
</script>
