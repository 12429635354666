<template>
    <v-data-table class="elevation-2" :loading="loading" :items-per-page="15" :items="items" :headers="headers">
      <template v-slot:item.date="{ item }">
        {{moment(item.date).format("DD/MM/YYYY")}}
      </template>
      <template v-slot:item.lastUpdate="{ item }">
        {{moment(item.lastUpdate).format("DD/MM/YYYY HH:mm:ss")}}
      </template>
    </v-data-table>
</template>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';

export default {
  mixins: [
    jobScheduler([
      {
        job: (vm) => vm.getData(),
        interval: 15000,
      },
    ]),
  ],

  props: {
    strategy: {},
  },

  data: () => ({
    headers: [
      { text: 'Fundo', value: 'fund' },
      { text: 'Ticker', value: 'ticker' },
      { text: 'Data', value: 'date' },
      { text: 'Atualizado', value: 'lastUpdate' },
      { text: 'Tipo', value: 'type' },
      { text: 'Evento', value: 'event' },
      { text: 'Preço', value: 'refPrice' },
      { text: 'Qtd', value: 'amount' },
      { text: 'V', value: 'version' },
    ],
    items: [],
    interval: false,
    loading: false,
  }),

  methods: {
    moment,
    async getData() {
      this.loading = true;
      const { data } = await api.quant.getExecutions(this.strategy.name);
      this.items = data;
      this.loading = false;
    },
  },
};
</script>
