var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex"},[_c('v-col',[_c('h2',{staticClass:"mb-3"},[_vm._v("Overview")])]),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data inicial","readonly":"","dense":""},model:{value:(_vm.startDateC),callback:function ($$v) {_vm.startDateC=$$v},expression:"startDateC"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startDateMenu = false; _vm.getData()}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data inicial","readonly":"","dense":""},model:{value:(_vm.endDateC),callback:function ($$v) {_vm.endDateC=$$v},expression:"endDateC"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.endDateMenu = false; _vm.getData()}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date).format("DD/MM/YYYY"))+" ")]}},{key:"item.lastUpdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.lastUpdate).format("DD/MM/YYYY HH:mm:ss"))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }