<template>
<v-dialog
        v-model="dialog"
        width="500"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="success"
          dark
          x-small
          v-bind="attrs"
          v-on="on"
        >
          Nova <v-icon small>mdi-plus</v-icon>
        </v-btn>
      </template>
       <v-card>
      <v-card-title
        class="text-body-1 grey lighten-2"
        primary-title
      >
        Nova Estratégia
      </v-card-title>

       <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          dense
          @submit.prevent="save"
        >
      <v-card-text>
          <v-text-field
            label="ID"
            single-line
            v-model="name"
            required
            :rules="required"
          ></v-text-field>
          <v-text-field
            label="Descrição"
            v-model="description"
            single-line
            required
            :rules="required"
          ></v-text-field>
          <v-text-field
            label="Cron"
            v-model="cron"
            persistent-hint
            :hint="cronHint"
            :rules="cronValidate"
            required
          ></v-text-field>
          <v-text-field
            v-model="tickers"
            label="Tickers (Separados por vírgula)"
            single-line
            required
            :rules="required"
          ></v-text-field>
          <span class="text-caption">Google Cloud Function</span>
          <v-text-field
            v-model="url"
            label="URL"
            single-line
            required
            :rules="required"
          ></v-text-field>
          <v-text-field
            label="Token"
            v-model="token"
            single-line
            required
            :rules="required"
          ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="red"
          text
          @click="dialog = false"
        >
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          type="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
              </v-form>
    </v-card>
    </v-dialog>
</template>

<script>
import cronstrue from 'cronstrue/i18n';
import api from '@/services/api';

export default {
  data() {
    return {
      // Form fields
      name: '',
      description: '',
      cron: '',
      tickers: '',
      url: '',
      token: '',
      valid: undefined,
      dialog: false,
      cronHint: '',
      // Validate
      required: [(v) => !!v || 'Campo obrigatório'],
      cronValidate: [
        (v) => !!v || 'Campo obrigatório',
        () => {
          try {
            cronstrue.toString(this.cron, { locale: 'pt_BR' });
            return true;
          } catch (error) {
            return 'Cron inválida';
          }
        }],
    };
  },
  watch: {
    cron() {
      try {
        this.cronHint = cronstrue.toString(this.cron, { locale: 'pt_BR' });
      } catch (error) {
        this.cronHint = 'Cron inválida';
      }
    },
  },
  methods: {
    async save() {
      if (!this.$refs.form.validate()) return;

      const tickers = [];
      this.tickers.split(',').forEach((ticker) => {
        tickers.push(ticker);
      });
      const payload = {
        name: this.name,
        description: this.description,
        cron: this.cron,
        tickers,
        gcp: {
          url: this.url,
          token: this.token,
        },

      };

      try {
        await api.quant.create(payload);
        this.$emit('created');
      } catch (error) {
        console.error(error);
      } finally {
        this.dialog = false;
      }
    },
  },
};
</script>
