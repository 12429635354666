<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="5" xl="4">
        <StrategyCard
          height="100%"
          :strategy="strategy"
          @function-run="() => getHistoryData()"
          v-if="strategy._id"
        />
      </v-col>

      <v-col cols="12" lg="7" xl="8">
        <ListExecutions  v-if="strategy.name" :strategy="strategy" />
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title>
            Histórico de execução
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="historyHeaders"
              :items="history"
              :items-per-page="10"
              :item-class="colorRows"
              must-sort
              sort-by="startedAt"
              sort-desc
              item-key="startedAt"
              show-expand
              single-expand
              expanded.sync="expanded"
            >
              <template v-slot:item.status="{ item }">
                <v-progress-circular
                  v-if="item.running"
                  :size="15"
                  indeterminate
                  color="primary"
                />

                <v-icon small v-else-if="item.error" class="mr-2" color="red">
                  mdi-alert-circle
                </v-icon>

                <v-icon small v-else class="mr-2" color="green">
                  mdi-checkbox-marked-circle
                </v-icon>
              </template>

              <template v-slot:item.startedAt="{ item }">
                {{moment(item.startedAt).format("DD/MM/YYYY HH:mm")}}
              </template>

              <template v-slot:item.finishedAt="{ item }">
                {{moment(item.finishedAt).format("DD/MM/YYYY HH:mm")}}
              </template>

              <template v-slot:item.executationTime="{ item }">
                <span v-if="item.executationTime"> {{item.executationTime / 1000}}s </span>
                <span v-else>--</span>
              </template>

              <template v-slot:item.triggeredBy="{ item }">
                <span class="text-no-wrap">
                  <template v-if="item.triggeredBy.cron">
                    Cron (<span class="text-caption">{{ item.triggeredBy.cron }}</span>)
                  </template>

                  <template v-if="item.triggeredBy.user">
                    Usuário (<span class="text-caption">{{ item.triggeredBy.user }}</span>)
                  </template>

                  <template v-if="item.triggeredBy.ticker">
                    Ticker <span class="text-caption">{{ item.triggeredBy.ticker }}</span>
                  </template>
                </span>
              </template>

              <template v-slot:expanded-item="{ item }">
                <td :colspan="historyHeaders.length">
                  <v-data-table hide-default-footer disable-pagination class="my-3" v-if="!item.error" :headers="headersResponse" :items="item.response">
                  </v-data-table>
                  <span v-else>
                    {{ item.error }}
                  </span>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';
import StrategyCard from './StrategyCard.vue';
import ListExecutions from './ListExecutions.vue';

export default {
  mixins: [
    jobScheduler([
      {
        job: (vm) => vm.getHistoryData(),
        interval: 15000,
      },
    ]),
  ],

  components: {
    StrategyCard,
    ListExecutions,
  },

  data: () => ({
    id: false,
    dialog: false,
    strategy: {},
    history: [],
    interval: false,
    headersResponse: [
      { text: 'Data', value: 'date' },
      { text: 'Tipo', value: 'type' },
      { text: 'Estratégia', value: 'strategy' },
      { text: 'Ticker', value: 'ticker' },
      { text: 'Qnt.', value: 'amount' },
      { text: 'Preço.', value: 'price' },
      { text: 'Evento.', value: 'event' },
    ],
    historyHeaders: [
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Gatilho', value: 'triggeredBy', sortable: false },
      { text: 'Iniciado em', value: 'startedAt' },
      { text: 'Finalizado em', value: 'finishedAt' },
      { text: 'Tempo de execução', value: 'executationTime' },
      { text: '', value: 'data-table-expand' },
    ],
    headers: [
      { text: 'ID', value: 'name' },
      { text: 'Descrição', value: 'Description' },
    ],
  }),

  async created() {
    await this.getData();
  },

  methods: {
    moment,
    colorRows(item) {
      /* eslint-disable-next-line */
      return (item.running ? 'blue lighten-5' : (item.error ? 'red lighten-5' : 'green lighten-5'));
    },

    async getData() {
      try {
        this.id = this.$route.params.id;
        const { data } = await api.quant.getById(this.id);
        this.strategy = data.strategy;
      } catch (error) {
        console.error(error);
      }
    },

    async getHistoryData() {
      try {
        const { data: dataHistory } = await api.quant.getHistoryById(this.id);
        this.history = dataHistory;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
