<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1 class="text-body-1">Quant - Estratégias</h1>
      </v-col>
      <v-col class="justify-end d-flex">
        <AddStrategy @created="strategyCreated" />
      </v-col>
    </v-row>

    <v-row>
      <template v-for="strategy in strategies">
        <v-col fill-height cols="12" md="4" :key="strategy._id">
          <StrategyCard
            height="100%"
            :to="`/quant/strategies/${strategy._id}`"
            :strategy="strategy"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/services/api';

import AddStrategy from './AddStrategy.vue';
import StrategyCard from './StrategyCard.vue';

export default {
  components: {
    AddStrategy,
    StrategyCard,
  },

  data() {
    return {
      dialog: false,
      strategies: [],
      headers: [
        { text: 'ID', value: 'name' },
        { text: 'Descrição', value: 'Description' },
      ],
    };
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        const { data } = await api.quant.list();
        this.strategies = data;
      } catch (error) {
        console.error(error);
      }
    },

    async strategyCreated() {
      await this.getData();
    },
  },
};
</script>
