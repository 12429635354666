<template>
  <v-card v-bind="$attrs" v-on="$listeners" :loading="loading">
    <template v-if="!foundStrategy">
      <v-skeleton-loader type="table-heading, table-tbody" />
    </template>

    <template v-else>
      <v-list-item two-line>
        <v-list-item-action class="mr-4">
          <v-icon :color="lastRun.error ? 'red' : 'green'">
            {{ lastRun.error ? 'mdi-alert-circle' : 'mdi-checkbox-marked-circle' }}
          </v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            {{ currentStrategy.name }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ currentStrategy.description }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltipEvents, attrs: tooltipAttrs }">
              <v-btn
                v-bind="tooltipAttrs"
                v-on="tooltipEvents"
                text
                icon
                color="green lighten-2"
                :loading="running"
                :disabled="running"
                @click.prevent="runFunction"
              >
                <v-icon>mdi-play</v-icon>
              </v-btn>
            </template>

            <template v-slot:default>
              {{ running ? 'Executando' : 'Executar' }}
            </template>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>

      <v-divider class="mx-4" />

      <v-card-text>
        <v-simple-table dense>
          <tbody>
            <tr>
              <td>Cron</td>
              <td>
                <code>{{ currentStrategy.cron }}</code>
                <br>
                <span class="text-caption">
                  {{ cronString }}
                </span>
              </td>
            </tr>

            <tr>
              <td>Próxima Execução:</td>
              <td>{{ nextRun ? moment(nextRun).format("DD/MM/YYYY HH:mm") : 'N/A' }}</td>
            </tr>

            <tr>
              <td>Gatilhos:</td>
              <td>{{ currentStrategy.tickers.length ? currentStrategy.tickers.join(', ') : 'Nenhum gatilho' }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-divider />

        <div v-if="lastRun" >
          <h6 class="text-subtitle-2 my-4">Última execução</h6>
          <v-simple-table dense :class="lastRun.error ? 'red lighten-4' : 'green lighten-5'">
            <tbody>
              <tr>
                <td>Gatilho</td>
                <td class="text-no-wrap">
                  <template v-if="lastRun.triggeredBy.cron">
                    Cron (<span class="text-caption">{{lastRun.triggeredBy.cron}}</span>)
                  </template>

                  <template v-if="lastRun.triggeredBy.user">
                    Usuário (<span class="text-caption">{{lastRun.triggeredBy.user}}</span>)
                  </template>

                  <template v-if="lastRun.triggeredBy.ticker">
                    Ticker (<span class="text-caption">{{lastRun.triggeredBy.ticker}}</span>)
                  </template>
                </td>
              </tr>

              <tr>
                <td>Iniciado em:</td>
                <td>{{moment(lastRun.startedAt).format("DD/MM/YYYY HH:mm:ss")}}</td>
              </tr>

              <tr>
                <td>Finalizado em:</td>
                <td>{{moment(lastRun.finishedAt).format("DD/MM/YYYY HH:mm:ss")}}</td>
              </tr>

              <tr>
                <td>Tempo de execução</td>
                <td>{{lastRun.executationTime / 1000}}s</td>
              </tr>

              <tr v-if="lastRun.error">
                <td>Error</td>
                <td>{{lastRun.error}}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import moment from 'moment-loyall';
import cronstrue from 'cronstrue/i18n';
import { isValidCron } from 'cron-validator';

import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';

export default {
  name: 'StrategyCard',

  inheritAttrs: false,

  mixins: [
    jobScheduler([
      {
        job: (vm) => vm.getData(),
        interval: 15000,
      },
    ]),
  ],

  props: {
    strategy: undefined,
  },

  data: () => ({
    currentStrategy: null,
    loading: false,
    lastRun: false,
    nextRun: false,
    running: false,
    interval: false,
  }),

  computed: {
    foundStrategy: (vm) => Object.keys(vm.currentStrategy ?? {}).length > 0,
    cronString: (vm) => (isValidCron(vm.currentStrategy?.cron)
      ? cronstrue.toString(vm.currentStrategy.cron, { locale: 'pt_BR' })
      : ''
    ),
  },

  methods: {
    moment,

    async getData() {
      this.loading = true;
      try {
        if (!this.strategy?._id) {
          return;
        }
        const { data } = await api.quant.getById(this.strategy._id);
        this.currentStrategy = data.strategy;
        this.lastRun = data.lastRun || false;
        this.nextRun = data.nextRun;
        this.running = data.running;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async runFunction() {
      await api.quant.runStrategy(this.strategy._id);
      await this.getData();
      this.$emit('function-run');
    },
  },
};
</script>
